import React from 'react';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import css from '@emotion/css';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { COLORS, BREAKPOINTS } from '../../ui/variables';
import { ReactComponent as ArrowRightIco } from '../../images/icons/black-chevron-right.svg';
import { LocationsV2PageQuery } from '../../../graphql-types';
import Heading1 from '../../ui/Heading1';
import { Subtitle } from '../../ui/TypographyStyles';
import ParagraphSmall from '../../ui/ParagraphSmall';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 50%;
  height: 75vh;
  overflow: hidden;

  @media ${BREAKPOINTS.TABLET} {
    height: 50vh;
  }

  @media ${BREAKPOINTS.MOBILE} {
    height: auto;
    width: 100%;
  }
`;

const HeadingWrap = styled.div`
  background: ${COLORS.NEW_BEIGE};
  width: 100%;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 12px;
  }
`;

const Heading = styled.div`
  padding: 80px 100px;
  margin: 0 auto;
  max-width: 900px;
  box-sizing: content-box;
  text-align: center;

  @media ${BREAKPOINTS.TABLET} {
    padding: 40px 60px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    padding: 60px 20px;
    margin: auto;
  }
`;

const LocationList = styled.ul`
  padding: 40px 100px;
  width: 100%;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media ${BREAKPOINTS.TABLET} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px 56px;
  }
`;

const Location = styled.li`
  border-top: 1px solid ${COLORS.LIGHT_GRAY};
  padding: 24px 0;
  margin: 0;
  max-width: 520px;
  width: 100%;

  @media ${BREAKPOINTS.DESKTOP} {
    &:first-child {
      border: none;
    }
  }

  @media ${BREAKPOINTS.TABLET} {
    max-width: none;
  }
`;

const LinkTo = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover * {
    color: ${COLORS.HOVER_BLACK};
  }
`;

const LocationName = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  color: ${COLORS.BLACK};

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const ArrowIco = styled(ArrowRightIco)`
  margin-right: 12px;
`;

const NumberOfLocations = styled(ParagraphSmall)`
  margin-bottom: 0;
`;

const ComingSoonPill = styled.span`
  background-color: ${COLORS.SECONDARY_GRAY};
  text-transform: capitalize;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  color: ${COLORS.BLACK};
  font-weight: 600;
  margin-bottom: 16px;
  display: inline-block;
  line-height: 12px;
  letter-spacing: 0.5px;
`;

interface Props {
  className?: string;
  textPill?: string;
}

export const ComingSoon = ({ className, textPill }: Props) => (
  <ComingSoonPill className={className}>
    {textPill ? textPill : 'Coming Soon'}
  </ComingSoonPill>
);

export const pageQuery = graphql`
  query LocationsV2Page {
    drJamie: file(relativePath: { eq: "photos/jamie-bao-square.jpg" }) {
      childImageSharp {
        fluid(quality: 100, fit: INSIDE, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Locations = ({ data }: { data: LocationsV2PageQuery }) => {
  return (
    <Layout>
      <SEO
        title='Modern &amp; Convenient Vet Practices | Small Door Veterinary'
        description='Our state-of-the-art veterinary practices are modern, stress-free environments, conveniently located across NYC, Boston &amp; Washington DC.'
      />
      <HeadingWrap>
        <Heading>
          <Heading1>Our Veterinary Practices</Heading1>
          <Subtitle
            css={css`
              max-width: 750px;
              margin: 0 auto;
            `}
          >
            Our practices are state-of-the-art, AAHA-accredited facilities with
            modern technology and warm hospitality designed to be stress-free
            for both our patients and human clients.
          </Subtitle>
        </Heading>
      </HeadingWrap>

      <Content>
        <Left>
          <LocationList>
            <Location>
              <LinkTo to='/locations/new-york-city'>
                <div>
                  <LocationName>New York City</LocationName>
                  <NumberOfLocations>5 locations</NumberOfLocations>
                </div>
                <ArrowIco />
              </LinkTo>
            </Location>

            <Location>
              <LinkTo to='/locations/boston'>
                <div>
                  <LocationName>Boston</LocationName>
                  <NumberOfLocations>2 locations</NumberOfLocations>
                </div>
                <ArrowIco />
              </LinkTo>
            </Location>

            <Location>
              <LinkTo to='/locations/washington-dc'>
                <div>
                  <LocationName>Washington DC</LocationName>
                  <NumberOfLocations>5 locations</NumberOfLocations>
                </div>
                <ArrowIco />
              </LinkTo>
            </Location>
          </LocationList>
        </Left>

        <Right>
          <Img
            css={css`
              height: 720px;
              margin: 0 auto;
            `}
            imgStyle={{
              objectPosition: 'center -100px',
            }}
            fluid={data.drJamie.childImageSharp.fluid}
          />
        </Right>
      </Content>
    </Layout>
  );
};

export default Locations;
