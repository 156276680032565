// Body Small

import styled from '@emotion/styled';
import { COLORS } from './variables';

const ParagraphSmall = styled.p`
  color: ${COLORS.BLACK};
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
`;

export default ParagraphSmall;
