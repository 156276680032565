// Title Large

import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const Heading1 = styled.h1`
  color: ${COLORS.BLACK};
  font-size: 64px;
  line-height: 68px;
  font-weight: 400;
  letter-spacing: -1.5px;
  margin-bottom: 32px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 24px;
  }
`;

export default Heading1;
